import { useMutation } from '@tanstack/react-query';

import { api, type APIError } from 'services/api';
import type { paths } from 'types/schemas/api-schema';

export const useDownloadAuditLogs = () => {
  return useMutation<
    void,
    APIError<paths['/audit/download']['get']['responses']['401']['content']['application/json']>,
    void
  >({
    mutationFn: async () => {
      const response = await api.url('/audit/download').get().res();

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.download = 'audit-logs.gz';
      aTag.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(aTag);
    },
  });
};
