import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { Custom } from 'components/scenes/Settings/Enrichments/Custom';

export const Route = createFileRoute('/_app/settings/enrichments/custom')({
  component: Custom,
  validateSearch: zodSearchValidator(
    z
      .object({})
      .or(
        z.object({
          enrichmentSidebarIntent: z.literal('create'),
        }),
      )
      .or(
        z.object({
          enrichmentSidebarIntent: z.literal('edit'),
          enrichmentId: z.string(),
          tab: z.enum(['assign-pipelines', 'edit-enrichment']),
        }),
      ),
  ),
});
