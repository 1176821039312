import { useCallback, useMemo, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
  type SortingState,
} from '@tanstack/react-table';
import { getRouteApi } from '@tanstack/react-router';

import { usePipelines } from 'services/api/pipelines';
import { useTenant } from 'services/api/tenants';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';

import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Authorize } from 'components/common/Authorize';
import { GearIcon, MixerHorizontalIcon } from 'components/common/Icons';
import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { Callout } from 'components/common/Callout';
import { Badge } from 'components/common/Badge';

import { PipelineSidebar } from './components/PipelineSidebar';
import { SettingsPageContainer } from '../components/SettingsPageContainer';

const Route = getRouteApi('/_app/settings/platform/pipelines');

type PipelineData = components['schemas']['fullPipeline'];
const pipelineColumnHelper = createColumnHelper<components['schemas']['fullPipeline']>();

export function PipelinesTabActionButtons() {
  const t = useTranslate();
  const navigate = Route.useNavigate();

  return (
    <Authorize policy={{ scope: 'pipeline:write' }}>
      <Button
        size="1"
        variant="outline"
        onClick={() => {
          void navigate({
            search: {
              pipelineSidebarIntent: 'create',
            },
          });
        }}
      >
        <MixerHorizontalIcon /> {t('settings.tabs.platform.subtabs.pipelines.actions.create_pipeline')}
      </Button>
    </Authorize>
  );
}

const TenantAssignment = ({ tenantId }: { tenantId: string }) => {
  const { data: tenant, isLoading, isError } = useTenant(tenantId);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isError) {
    return <Text>Error</Text>;
  }

  if (!tenant) {
    return null;
  }

  return <Text>{tenant.data.name}</Text>;
};

const PipelineCreateEditCallout = ({
  pipeline,
  onClose,
}: {
  pipeline: components['schemas']['fullPipeline'];
  onClose: () => void;
}) => {
  const t = useTranslate();
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="pipeline-updated-callout"
    >
      {t('settings.tabs.platform.subtabs.pipelines.created_callout', {
        pipelineName: pipeline.name,
      })}
    </Callout>
  );
};

const PipelineDeletedCallout = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslate();
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="pipeline-deleted-callout"
    >
      {t('settings.tabs.platform.subtabs.pipelines.deleted_callout')}
    </Callout>
  );
};

export function Pipelines() {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const pipelinesQuery = usePipelines();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [createdPipeline, setCreatedPipeline] = useState<PipelineData | null>(null);
  const [editedPipeline, setEditedPipeline] = useState<PipelineData | null>(null);
  const [hasPipelineBeenDeleted, setHasPipelineBeenDeleted] = useState(false);
  const searchParams = Route.useSearch();
  const pipelineSidebarIntent = 'pipelineSidebarIntent' in searchParams ? searchParams.pipelineSidebarIntent : null;

  const handlePipelineEditClick = useCallback(
    (pipelineId: string) => {
      void navigate({
        search: { pipelineSidebarIntent: 'edit', pipelineId },
      });
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return [
      pipelineColumnHelper.accessor('name', {
        header: () => <Text>{t('common.pipeline')}</Text>,
        cell: (info) => <Text>{info.getValue()}</Text>,
      }),
      pipelineColumnHelper.accessor('tenantId', {
        header: () => <Text>{t('common.tenant')}</Text>,
        cell: (info) => <TenantAssignment tenantId={info.getValue()} />,
      }),
      pipelineColumnHelper.accessor('source.name', {
        header: () => <Text>{t('settings.tabs.platform.subtabs.pipelines.table.headers.data_source')}</Text>,
        cell: (info) => <Badge color="gray">{info.getValue()}</Badge>,
      }),
      pipelineColumnHelper.accessor('translation.name', {
        header: () => <Text>{t('settings.tabs.platform.subtabs.pipelines.table.headers.data_type')}</Text>,
        cell: (info) => <Badge color="bronze">{info.getValue()}</Badge>,
      }),
      pipelineColumnHelper.accessor('id', {
        header: () => <Text className="w-full text-right">{t('common.actions_label')}</Text>,
        cell: (info) => {
          return (
            <div key={info.getValue()} className="text-right">
              <Authorize
                policy={{
                  scope: 'pipeline:write',
                  tenantId: info.row.original.tenantId,
                }}
              >
                <Button
                  size="1"
                  variant="outline"
                  color="gray"
                  onClick={() => handlePipelineEditClick(info.getValue())}
                >
                  <GearIcon /> {t('common.settings')}
                </Button>
              </Authorize>
            </div>
          );
        },
        enableSorting: false,
      }),
    ];
  }, [handlePipelineEditClick, t]);

  const table = useReactTable({
    data: pipelinesQuery.data?.data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (pipelinesQuery.isError) {
    return (
      <SettingsPageContainer>
        {t('common.errors.error')}: {pipelinesQuery.error?.message}
      </SettingsPageContainer>
    );
  }

  if (pipelinesQuery.isLoading) {
    return <SettingsPageContainer>{t('common.loading')}</SettingsPageContainer>;
  }

  return (
    <>
      <PipelineSidebar
        viewMode={pipelineSidebarIntent!}
        onSuccess={pipelineSidebarIntent === 'create' ? setCreatedPipeline : setEditedPipeline}
        onDeleteSuccess={() => {
          setHasPipelineBeenDeleted(true);
        }}
      />

      <SettingsPageContainer>
        {createdPipeline && (
          <PipelineCreateEditCallout pipeline={createdPipeline} onClose={() => setCreatedPipeline(null)} />
        )}
        {editedPipeline && (
          <PipelineCreateEditCallout pipeline={editedPipeline} onClose={() => setEditedPipeline(null)} />
        )}
        {hasPipelineBeenDeleted && <PipelineDeletedCallout onClose={() => setHasPipelineBeenDeleted(false)} />}

        <Table.Root variant="surface" data-test-id="settings-pipelines-table">
          <Table.Header className="bg-neutral-a2">
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <SortableColumnHeaderCell key={header.id} header={header} />
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Row key={row.id} align="center">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </SettingsPageContainer>
    </>
  );
}
