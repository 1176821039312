import { useState } from 'react';

import { usePipelines } from 'services/api/pipelines';
import { useDeleteTenant } from 'services/api/tenants';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';

import { Label } from 'components/common/Label';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { TextField } from 'components/common/TextField';
import { Translate } from 'components/common/Translate';
import { InlineDialog } from 'components/common/InlineDialog';

type DeleteUserInlineDialogProps = {
  tenant: components['schemas']['tenant'];
  onCancel: () => void;
  onSuccess: () => void;
};

const TenantDepedentResources = ({ tenantId }: { tenantId: components['schemas']['tenant']['id'] }) => {
  const t = useTranslate();
  const tenantPipelinesQuery = usePipelines(tenantId);

  return tenantPipelinesQuery.isLoading ? (
    <div>{t('common.loading')}</div>
  ) : (
    <ul className="list-inside list-disc">
      {tenantPipelinesQuery.data?.data.map((pipeline) => (
        <li key={pipeline.id}>
          {t('settings.tabs.platform.subtabs.tenants.sidebar.pipeline_item', {
            pipelineName: pipeline.name,
          })}
        </li>
      ))}
    </ul>
  );
};

export const DeleteTenantInlineDialog = ({ tenant, onCancel, onSuccess }: DeleteUserInlineDialogProps) => {
  const t = useTranslate();
  const [tenantNameConfirm, setTenantNameConfirm] = useState('');
  const deleteTenantMutation = useDeleteTenant(tenant.id);

  const handleDeleteUser = () => {
    deleteTenantMutation.mutate(undefined, {
      onSuccess,
    });
  };

  const isDisabled = tenantNameConfirm !== tenant.name;

  return (
    <InlineDialog
      title={t('settings.tabs.platform.subtabs.tenants.sidebar.delete_tenant')}
      className="mb-3"
      footer={
        <>
          <Button size="1" color="gray" variant="soft" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            size="1"
            color="red"
            disabled={isDisabled}
            isLoading={deleteTenantMutation.isPending}
            onClick={handleDeleteUser}
          >
            {t('common.permanently_delete')}
          </Button>
        </>
      }
    >
      <div className="mb-1 leading-relaxed text-error-11">
        <Translate
          tkey="settings.tabs.platform.subtabs.tenants.sidebar.delete_tenant_subtitle"
          components={{
            newLine: <br />,
          }}
        />
      </div>

      <TenantDepedentResources tenantId={tenant.id} />

      <Label htmlFor="tenantNameInput" className="mb-1 mt-5 inline-block">
        {t('settings.tabs.platform.subtabs.tenants.sidebar.tenant_name_input.label')}
      </Label>
      <TextField
        id="tenantNameInput"
        size="2"
        placeholder={t('settings.tabs.platform.subtabs.tenants.sidebar.tenant_name_input.placeholder')}
        variant="surface"
        value={tenantNameConfirm}
        onChange={(e) => setTenantNameConfirm(e.target.value)}
      />

      {deleteTenantMutation.isError && (
        <Callout className="mt-3" variant="soft" status="error" size="1" onClose={() => deleteTenantMutation.reset()}>
          {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.delete_user_prompt.error')}
        </Callout>
      )}
    </InlineDialog>
  );
};
