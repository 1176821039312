import { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';

import { useCreateEnrichment, useUpdateEnrichmentDataset } from 'services/api/custom-enrichments';

import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';

import { LaptopIcon } from 'components/common/Icons';
import { useWriteAccessTenants } from './useWriteAccessTenants';

import {
  CustomEnrichmentForm,
  type CreateEnrichmentFormFields,
  type EditEnrichmentFormFields,
  type EnrichmentContentProps,
} from '../CustomEnrichmentForm';

type ViewMode = 'create' | 'edit';
type EnrichmentDataSet = components['schemas']['customEnrichmentSetMetadata'];

export const EditEnrichmentTabItem = () => {
  return <LaptopIcon className="size-4" />;
};

export function CustomEnrichmentCreateContent({
  onSuccess,
  viewMode,
}: {
  onSuccess: EnrichmentContentProps['onSuccess'];
  viewMode: Extract<ViewMode, 'create'>;
}) {
  const t = useTranslate();
  const navigate = useNavigate();
  const createEnrichmentMutation = useCreateEnrichment();
  const { tenants: tenantsList, isLoading, error } = useWriteAccessTenants();

  const handleSubmit = (data: CreateEnrichmentFormFields) => {
    createEnrichmentMutation.mutate(data, {
      onSuccess: (response) => {
        onSuccess(response.data);

        void navigate({
          search: undefined,
        });
      },
    });
  };

  if (isLoading) {
    return <p>{t('common.loading')}</p>;
  }

  if (error) {
    return <p>{t('common.errors.error')}</p>;
  }

  return (
    <CustomEnrichmentForm
      isLoading={createEnrichmentMutation.isPending}
      error={createEnrichmentMutation.error?.json}
      onCloseCallout={createEnrichmentMutation.reset}
      onSubmit={handleSubmit}
      viewMode={viewMode}
      tenants={tenantsList}
      updateMode="replace"
      setUpdateMode={() => {}}
    />
  );
}

export function CustomEnrichmentEditContent({
  onSuccess,
  onPartialSuccess,
  viewMode,
  enrichment,
}: {
  onSuccess: EnrichmentContentProps['onSuccess'];
  onPartialSuccess: EnrichmentContentProps['onPartialSuccess'];
  viewMode: Extract<ViewMode, 'edit'>;
  enrichment: EnrichmentDataSet;
}) {
  const t = useTranslate();
  const navigate = useNavigate();
  const [updateMode, setUpdateMode] = useState<'replace' | 'append'>('replace');

  const updateEnrichmentMutation = useUpdateEnrichmentDataset(enrichment.id, updateMode);
  const { tenants: tenantsList, isLoading, error } = useWriteAccessTenants();

  const handleSubmit = async (data: EditEnrichmentFormFields) => {
    if (!enrichment.id) {
      return;
    }

    if (!data.file || !(data.file instanceof File)) {
      return;
    }

    const csvContent = await data.file.text();
    const fileName = data.file.name;

    updateEnrichmentMutation.mutate(csvContent, {
      onSuccess: (data) => {
        if (data.status === 'success') {
          onSuccess({ ...enrichment, file: fileName });
        } else if (data.status === 'partial') {
          onPartialSuccess({ ...enrichment, file: fileName }, data.errorStrings);
        }

        void navigate({
          search: undefined,
        });
      },
    });
  };

  if (isLoading) {
    return <p>{t('common.loading')}</p>;
  }

  if (!enrichment) {
    return <p>{t('settings.tabs.enrichments.subtabs.custom.sidebar.enrichment_not_found')}</p>;
  }

  if (error) {
    return <p>{t('common.errors.error')}</p>;
  }

  return (
    <div className="mt-1 h-full">
      <CustomEnrichmentForm
        enrichment={enrichment}
        isLoading={updateEnrichmentMutation.isPending}
        error={updateEnrichmentMutation.error?.json}
        onCloseCallout={updateEnrichmentMutation.reset}
        onSubmit={handleSubmit}
        viewMode={viewMode}
        tenants={tenantsList}
        updateMode={updateMode}
        setUpdateMode={setUpdateMode}
      />
    </div>
  );
}
