import { useState, type ChangeEvent } from 'react';
import { MinusCircledIcon, MixerHorizontalIcon, PlusCircledIcon } from 'components/common/Icons';

import type { components } from 'types/schemas/api-schema';
import type { APIError } from 'services/api';
import { useTranslate } from 'services/i18n/useTranslate';
import { usePipelines, useUpdatePipelineEnrichments } from 'services/api/pipelines';

import { TextField } from 'components/common/TextField';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';

type PlatformEnrichment = components['schemas']['enrichmentProfile'];
type FullPipeline = components['schemas']['fullPipeline'];
type EnrichmentSet = components['schemas']['enrichmentSet'];
type PipelineWithEnrichmentSet = {
  id: string;
  name: string;
  enrichmentSet?: EnrichmentSet;
};
type AssignmentButtonProps = {
  pipelineId: string;
  tenantId: string;
  enrichmentType: string;
  onError: (error: any) => void;
  currentEnrichments: EnrichmentSet['enrichments'];
  isDisabled?: boolean;
};

export const AssignPipelineTabItem = () => {
  return <MixerHorizontalIcon className="size-4" />;
};

const PIPELINES_EMPTY_ARRAY: FullPipeline[] = [];

const EnrichmentButton = ({
  pipelineId,
  tenantId,
  enrichmentType,
  currentEnrichments = [],
  onError,
  isDisabled,
  isAssign,
}: AssignmentButtonProps & { isAssign: boolean }) => {
  const t = useTranslate();
  const updatePipelineEnrichmentsQuery = useUpdatePipelineEnrichments(pipelineId);

  const handleClick = () => {
    const updatedEnrichments = currentEnrichments.map((enrichment) =>
      enrichment.type === enrichmentType ? { ...enrichment, enabled: isAssign } : enrichment,
    );

    updatePipelineEnrichmentsQuery.mutate(
      {
        tenantId: tenantId,
        pipelineId: pipelineId,
        enrichments: updatedEnrichments,
      },
      { onError },
    );
  };

  return (
    <Button
      variant="soft"
      isLoading={updatePipelineEnrichmentsQuery.isPending}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {isAssign ? (
        <PlusCircledIcon className="size-4" aria-hidden />
      ) : (
        <MinusCircledIcon className="size-4" aria-hidden />
      )}
      {t(isAssign ? 'common.add' : 'common.remove')}
    </Button>
  );
};

const filterPipelines = (pipelines: PipelineWithEnrichmentSet[], enrichmentType: string, enabled: boolean) => {
  return pipelines.filter((pipeline) =>
    pipeline.enrichmentSet?.enrichments?.some((e) => e.type === enrichmentType && e.enabled === enabled),
  );
};

export const PlatformEnrichmentsAssignPipelineContent = ({ enrichment }: { enrichment: PlatformEnrichment }) => {
  const t = useTranslate();
  const pipelineQuery = usePipelines();
  const [searchTerm, setSearchTerm] = useState('');
  const [assignmentError, setAssignmentError] = useState<APIError<components['responses']['standardError']> | null>(
    null,
  );

  const pipelineList = (pipelineQuery?.data?.data || PIPELINES_EMPTY_ARRAY).map((p) => ({
    id: p.id,
    name: p.name!,
    enrichmentSet: p.enrichmentSet || undefined,
  }));

  const assignedPipelines = filterPipelines(pipelineList, enrichment.type, true);
  const unassignedPipelines = filterPipelines(pipelineList, enrichment.type, false);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filterPipelinesBySearchTerm = (pipelines: PipelineWithEnrichmentSet[]) => {
    if (!searchTerm) return pipelines;
    return pipelines.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const filteredAssignedPipelines = filterPipelinesBySearchTerm(assignedPipelines);
  const filteredUnassignedPipelines = filterPipelinesBySearchTerm(unassignedPipelines);

  return (
    <div className="flex h-full flex-col gap-3">
      <div className="mt-3 flex flex-col gap-8">
        <TextField placeholder={t('common.find')} value={searchTerm} onChange={handleSearch} />

        <div>
          <Text weight="medium">{t('settings.tabs.enrichments.subtabs.platform.sidebar.assigned_pipelines')}</Text>

          <ul>
            {filteredAssignedPipelines.length > 0 ? (
              filteredAssignedPipelines.map((pipeline) => (
                <li key={pipeline.id} className="my-2 flex items-center justify-between space-x-1">
                  <div className="flex h-8 w-full items-center rounded-2 bg-neutral-1 px-3 drop-shadow-sm">
                    {pipeline?.name}
                  </div>
                  <EnrichmentButton
                    pipelineId={pipeline.id}
                    tenantId={pipeline.enrichmentSet?.tenantId || ''}
                    enrichmentType={enrichment.type}
                    currentEnrichments={pipeline.enrichmentSet?.enrichments || []}
                    onError={setAssignmentError}
                    isAssign={false}
                  />
                </li>
              ))
            ) : (
              <Text>{t('settings.tabs.enrichments.subtabs.platform.sidebar.no_pipelines_found')}</Text>
            )}
          </ul>
        </div>

        <div>
          <Text weight="medium">{t('settings.tabs.enrichments.subtabs.platform.sidebar.unassigned_pipelines')}</Text>

          <ul>
            {filteredUnassignedPipelines.length > 0 ? (
              filteredUnassignedPipelines.map((pipeline) => (
                <li key={pipeline.id} className="my-2 flex items-center justify-between space-x-1">
                  <div className="flex h-8 w-full items-center rounded-2 bg-neutral-1 px-3 drop-shadow-sm">
                    {pipeline?.name}
                  </div>
                  <EnrichmentButton
                    pipelineId={pipeline.id}
                    tenantId={pipeline.enrichmentSet?.tenantId || ''}
                    enrichmentType={enrichment.type}
                    currentEnrichments={pipeline.enrichmentSet?.enrichments || []}
                    onError={setAssignmentError}
                    isAssign={true}
                  />
                </li>
              ))
            ) : (
              <Text>{t('settings.tabs.enrichments.subtabs.platform.sidebar.no_pipelines_found')}</Text>
            )}
          </ul>
        </div>
      </div>

      {assignmentError && (
        <Callout status="error" onClose={() => setAssignmentError(null)}>
          {assignmentError.message}
        </Callout>
      )}
    </div>
  );
};
