import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/reports')({
  component: Outlet,
  meta: () => [
    {
      name: 'pageTitle',
      content: 'reports.page_title',
    },
    {
      name: 'metaTitle',
      content: 'reports.page_title',
    },
    {
      name: 'pageDescription',
      content: 'reports.page_description',
    },
  ],
});
