export const dateFormatShort = 'MM/dd/yy';
export const dateFormatMedium = 'MMM d, yyyy';
export const dateTimeFormatCommon = 'MM/dd/yyyy hh:mm a';
export const dateTimeFormatShort = 'MMM d, h:mmaaa';
export const timeFormatSimple = 'HH:mm';
export const dateTimeFormatFull = 'MMM d, hh:mm:ss aaa';
export const dateTimeFormatWithoutSeconds = 'MMM d, hh:mm aaa';
export const timeFormat = 'h:mm aaa';
export const dateTimeWithTimezone = 'MMM d, hh:mm OOO';
export const fullDateTimeFormat = 'MMM d, yyyy, hh:mm a';
