import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type paths, type components } from 'types/schemas/api-schema';
import { api, type APIError } from '.';

export const usePipelines = (tenantId?: components['schemas']['tenant']['id']) => {
  const queryKey = tenantId ? ['pipelines', tenantId] : ['pipelines'];
  return useQuery<
    paths['/ingest/pipelines']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/ingest/pipelines']['get']['responses']['400']['content']['application/json']>
  >({
    queryKey,
    queryFn: () => {
      const query = tenantId ? { tenantId } : {};
      return api
        .url(`/ingest/pipelines`)
        .query(query)
        .get()
        .json<paths['/ingest/pipelines']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export type GetPipelineResponse =
  paths['/ingest/pipelines/{pipelineId}']['get']['responses']['200']['content']['application/json'];
export type GetPipelineError =
  paths['/ingest/pipelines/{pipelineId}']['get']['responses']['400']['content']['application/json'];

export const usePipeline = (pipelineId?: components['schemas']['idString']) => {
  return useQuery<GetPipelineResponse, APIError<GetPipelineError>>({
    queryKey: ['pipeline', pipelineId],
    queryFn: () => {
      return api.url(`/ingest/pipelines/${pipelineId}`).get().json<GetPipelineResponse>();
    },
    enabled: !!pipelineId,
  });
};

type CreatePipelineResponse = paths['/ingest/pipelines']['post']['responses']['201']['content']['application/json'];

export const useCreatePipeline = () => {
  const queryClient = useQueryClient();

  return useMutation<
    CreatePipelineResponse,
    APIError<paths['/ingest/pipelines']['post']['responses']['400']['content']['application/json']>,
    paths['/ingest/pipelines']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (pipeline) => {
      const response = await api.url('/ingest/pipelines').post(pipeline).json<CreatePipelineResponse>();

      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });

      return response;
    },
  });
};

export const useEditPipeline = (pipelineid: components['schemas']['idString']) => {
  const queryClient = useQueryClient();

  return useMutation<
    GetPipelineResponse,
    APIError<paths['/ingest/pipelines/{pipelineId}']['get']['responses']['400']['content']['application/json']>,
    components['schemas']['fullPipeline']
  >({
    mutationFn: async (updatedPipeline) => {
      const response = await api
        .url(`/ingest/pipelines/${pipelineid}`)
        .put(updatedPipeline)
        .json<GetPipelineResponse>();

      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });

      return response;
    },
  });
};

export const useDeletePipeline = (pipelineId: components['schemas']['idString']) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    APIError<paths['/ingest/pipelines/{pipelineId}']['delete']['responses']['400']['content']['application/json']>
  >({
    mutationFn: async () => {
      await api.url(`/ingest/pipelines/${pipelineId}`).delete().res();

      return;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });
    },
  });
};

export type UpdatePipelineEnrichmentsRequest =
  paths['/ingest/pipelines/{pipelineId}/enrichments']['put']['requestBody']['content']['application/json'];

export const useUpdatePipelineEnrichments = (pipelineId: components['schemas']['idString']) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    APIError<
      paths['/ingest/pipelines/{pipelineId}/enrichments']['put']['responses']['400']['content']['application/json']
    >,
    UpdatePipelineEnrichmentsRequest
  >({
    mutationFn: async (enrichments) => {
      await api
        .url(`/ingest/pipelines/${pipelineId}/enrichments`)
        .put(enrichments)
        .json<UpdatePipelineEnrichmentsRequest>();

      await queryClient.invalidateQueries({
        queryKey: ['pipelines'],
      });

      await queryClient.invalidateQueries({
        queryKey: ['pipeline', pipelineId, 'enrichments'],
      });
    },
  });
};
