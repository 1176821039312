import { useEffect, useMemo, useState, type ReactNode } from 'react';
import { getRouteApi } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import { useEnrichments } from 'services/api/custom-enrichments';

import { Drawer } from 'components/common/Drawer';
import { VerticalTabContent, VerticalTabs, VerticalTabsList, VerticalTabTrigger } from 'components/common/VerticalTabs';
import { Authorize } from 'components/common/Authorize';
import { Button } from 'components/common/Button';

import { EditEnrichmentTabItem, CustomEnrichmentEditContent } from './tabs/CustomEnrichmentGeneralDataTab';
import {
  CustomEnrichmentsAssignPipelineContent,
  AssignPipelineTabItem,
} from './tabs/CustomEnrichmentAssignPipelineTab';
import type { EnrichmentContentProps } from './CustomEnrichmentForm';
import { DeleteCustomEnrichmentInlineDialog } from './DeleteCustomEnrichmentInlineDialog';

const Route = getRouteApi('/_app/settings/enrichments/custom');

type ViewMode = 'create' | 'edit';
export type EnrichmentSidebarProps = {
  onSuccess: EnrichmentContentProps['onSuccess'];
  onPartialSuccess: EnrichmentContentProps['onPartialSuccess'];
  onDeletion: EnrichmentContentProps['onDeletion'];
};

export function EditCustomEnrichmentSidebar({ onSuccess, onPartialSuccess, onDeletion }: EnrichmentSidebarProps) {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const enrichmentQuery = useEnrichments();
  const viewMode: ViewMode = 'edit';
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const tab =
    (('enrichmentSidebarIntent' in searchParams &&
      searchParams.enrichmentSidebarIntent === viewMode &&
      searchParams.tab) as string) || 'settings';

  const isOpen = 'enrichmentSidebarIntent' in searchParams && searchParams.enrichmentSidebarIntent === viewMode;

  const enrichmentId = ('enrichmentSidebarIntent' in searchParams &&
    searchParams.enrichmentSidebarIntent === viewMode &&
    searchParams.enrichmentId) as string;

  const enrichment = useMemo(
    () => enrichmentQuery.data?.data.find((u) => u.id === enrichmentId),
    [enrichmentId, enrichmentQuery.data],
  );

  const onOpenChange = (value: boolean) => {
    void navigate({
      search: value ? { enrichmentSidebarIntent: viewMode } : {},
    });
  };

  useEffect(() => {
    if (isOpen) {
      setIsDeleteDialogOpen(false);
    }
  }, [isOpen]);

  const errorOrContent = (node: ReactNode) => {
    if (enrichmentQuery.error) {
      return <p>{t('common.errors.failed_to_retrieve_data')}</p>;
    }

    if (enrichmentQuery.isLoading) {
      return <p>{t('common.loading')}</p>;
    }

    if (!enrichment) {
      return <p>{t('settings.tabs.enrichments.subtabs.custom.sidebar.enrichment_not_found')}</p>;
    }

    return node;
  };

  const onDeleteEnrichmentSuccess = () => {
    setIsDeleteDialogOpen(false);

    if (enrichment) {
      onDeletion(enrichment);
      void navigate({
        search: {},
      });
    }
  };

  return (
    <Drawer
      title={t('settings.tabs.enrichments.subtabs.custom.sidebar.edit_title')}
      description={t('settings.tabs.enrichments.subtabs.custom.sidebar.edit_description', {
        enrichmentName: enrichment?.name,
      })}
      mode="modal"
      side="right"
      size="large"
      insetContent
      isOpen={isOpen}
      actions={
        enrichment && (
          <Authorize
            policy={{
              scope: 'pipeline:delete',
            }}
          >
            <Button size="1" color="red" onClick={() => setIsDeleteDialogOpen(true)}>
              {t('settings.tabs.enrichments.subtabs.custom.sidebar.delete_enrichment_set')}
            </Button>
          </Authorize>
        )
      }
      onOpenChange={onOpenChange}
    >
      <VerticalTabs
        value={tab}
        onValueChange={(tab) => {
          void navigate({
            search: (prev) => ({
              ...prev,
              tab: tab as 'edit-enrichment' | 'assign-pipelines',
            }),
          });
        }}
        size="2"
        orientation="vertical"
        paddingTop={8}
      >
        <VerticalTabsList>
          <VerticalTabTrigger value="edit-enrichment">
            <EditEnrichmentTabItem />
          </VerticalTabTrigger>
          <VerticalTabTrigger value="assign-pipelines">
            <AssignPipelineTabItem />
          </VerticalTabTrigger>
        </VerticalTabsList>

        <VerticalTabContent value="edit-enrichment">
          {isDeleteDialogOpen && enrichment && (
            <DeleteCustomEnrichmentInlineDialog
              enrichmentId={enrichment.id}
              enrichmentName={enrichment.name}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onSuccess={onDeleteEnrichmentSuccess}
            />
          )}
          {errorOrContent(
            enrichment && (
              <CustomEnrichmentEditContent
                onSuccess={onSuccess}
                onPartialSuccess={onPartialSuccess}
                viewMode={viewMode}
                enrichment={enrichment}
              />
            ),
          )}
        </VerticalTabContent>
        <VerticalTabContent value="assign-pipelines">
          {isDeleteDialogOpen && enrichment && (
            <DeleteCustomEnrichmentInlineDialog
              enrichmentId={enrichment.id}
              enrichmentName={enrichment.name}
              onCancel={() => setIsDeleteDialogOpen(false)}
              onSuccess={onDeleteEnrichmentSuccess}
            />
          )}
          {errorOrContent(enrichment && <CustomEnrichmentsAssignPipelineContent enrichment={enrichment} />)}
        </VerticalTabContent>
      </VerticalTabs>
    </Drawer>
  );
}
