import { useCallback } from 'react';
import { useNavigate, Outlet, useRouterState } from '@tanstack/react-router';

import { TabTrigger, Tabs, TabsList } from 'components/common/Tabs';
import { useTranslate } from 'services/i18n/useTranslate';
import { useLazyHasAccess, type Policy } from 'services/auth/useHasAccess';
import type { FlattenedTranslationKeys } from 'types/translations';

type RouteDefinition = {
  label: FlattenedTranslationKeys;
  isActive: (link: string) => boolean;
  policy: Policy;
} & (
  | {
      to: string;
    }
  | {
      getTo: (getHasAccess: (policy: Policy) => boolean) => string;
    }
);

const ROUTES = [
  {
    getTo: (getHasAccess) => {
      if (getHasAccess({ scope: 'tenant:read' })) {
        return '/settings/platform/tenants';
      }
      return '/settings/platform/pipelines';
    },
    label: 'settings.tabs.platform.label',
    isActive: (link: string) => link.startsWith('/settings/platform'),
    policy: {
      anyPolicy: [{ scope: 'tenant:read' }, { scope: 'pipeline:read' }],
    },
  },
  {
    to: '/settings/enrichments/custom',
    label: 'settings.tabs.enrichments.label',
    isActive: (link: string) => link.startsWith('/settings/enrichments'),
    policy: { scope: 'custom-enrichment:read' },
  },
  {
    getTo: (getHasAccess) => {
      if (getHasAccess({ scope: 'user:read' })) {
        return '/settings/users-and-access/users';
      }
      return '/settings/users-and-access/roles';
    },
    label: 'settings.tabs.usersAndAccess.label',
    isActive: (link: string) => link.startsWith('/settings/users-and-access'),
    policy: {
      anyPolicy: [{ scope: 'user:read' }, { scope: 'role:read' }],
    },
  },
] as const satisfies RouteDefinition[];

export function SettingsScene() {
  const t = useTranslate();
  const navigate = useNavigate();
  const { location } = useRouterState();
  const getHasAccess = useLazyHasAccess();

  const matchedRoute = ROUTES.find((route) => route.isActive(location.pathname)) || ROUTES[0];

  const handleTabChange = useCallback(
    (newTab: string) => {
      void navigate({
        to: newTab,
        search: {},
      });
    },
    [navigate],
  );

  const getValue = (route: RouteDefinition) => {
    if ('to' in route) {
      return route.to;
    }
    return route.getTo(getHasAccess);
  };

  return (
    <div className="w-full space-y-4 pb-6">
      <Tabs size="2" value={getValue(matchedRoute)} onValueChange={handleTabChange}>
        <TabsList className="bg-neutral-3 dark:bg-neutral-6" data-test-id="settings-tabs">
          {ROUTES.map((route) => {
            if (!getHasAccess(route.policy)) {
              return null;
            }

            return (
              <TabTrigger key={route.label} value={getValue(route)}>
                {t(route.label)}
              </TabTrigger>
            );
          })}
        </TabsList>

        <Outlet />
      </Tabs>
    </div>
  );
}
