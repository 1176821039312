import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { cn } from 'utils/styles';
import { useStore } from 'services/store';
import { useTranslate } from 'services/i18n/useTranslate';
import { useLazyHasAccess } from 'services/auth/useHasAccess';

import { Text } from 'components/common/Text';
import { HamburgerMenuIcon } from 'components/common/Icons';

import { MenuLogo } from './MenuLogo';
import { MenuItem } from './MenuItem';
import { menuConfig } from './config';
import { menuIcons } from './menuIcons';

export const EXPANDED_WIDTH = 232;
export const COLLAPSED_WIDTH = 60;

export function MainMenu() {
  const t = useTranslate();
  const { expanded, setExpanded } = useStore((s) => s.menu);
  const navWidth = expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH;

  const getHasAccess = useLazyHasAccess();

  const toggleMenu = () => setExpanded(!expanded);

  const filteredMenuConfig = menuConfig.map((section) => ({
    ...section,
    items: section.items.filter((item) => {
      if (item.policy) {
        return getHasAccess(item.policy);
      }
      return true;
    }),
  }));

  return (
    <NavigationMenu.Root
      orientation="vertical"
      style={{ width: navWidth, minWidth: navWidth }}
      aria-label={t('navigation.aria_label')}
      className={`fixed top-0 z-20 translate-x-0 translate-y-0 transform overflow-hidden border-r border-neutral-5 bg-neutral-2 shadow-lg shadow-neutral-4 transition-[width,min-width] duration-200 ease-in-out`}
    >
      <NavigationMenu.List className="flex h-dvh flex-col">
        <MenuLogo expanded={expanded} />

        {filteredMenuConfig.map((section, i) => {
          const showTitle = expanded && section.tkey;

          return (
            <React.Fragment key={i}>
              <div
                className={cn(
                  `${i > 0 && 'mt-8'} h-7 gap-y-2 pl-5 pr-2 leading-6 transition-opacity duration-200 ease-in-out ${showTitle ? 'opacity-100' : 'opacity-0'}`,
                )}
              >
                <Text weight="medium" size="2" className="whitespace-nowrap">
                  {section.tkey ? t(section.tkey) : ''}
                </Text>
              </div>
              {section.items.map(({ icon, ...item }) => {
                const Icon = menuIcons[icon];

                return (
                  <NavigationMenu.Item key={item.tkey}>
                    <MenuItem icon={Icon} {...item} expanded={expanded} />
                  </NavigationMenu.Item>
                );
              })}
            </React.Fragment>
          );
        })}

        <div className="flex-1" />

        <MenuItem tkey={'menu.minimize_navigation'} icon={HamburgerMenuIcon} onClick={toggleMenu} expanded={expanded} />
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
