import { defaultParseSearch } from '@tanstack/react-router';
import type { FetchLike, WretchOptions } from 'wretch';

function executeMockedAPI<T extends object>(
  mockedAPI: T,
  path: string,
  method: string,
  queryParams: unknown,
  body: unknown,
) {
  const params: Record<string, any> = {};

  const pathWithoutVersion = path.replace(/\/v\d+\//, '/');

  const matchingPath = Object.keys(mockedAPI).find((api) => {
    const apiSegments = api.split('/');
    const pathSegments = pathWithoutVersion.split('/');

    if (apiSegments.length !== pathSegments.length) {
      return false;
    }

    return apiSegments.every((segment, index) => {
      if (segment.startsWith('{') && segment.endsWith('}')) {
        const paramName = segment.substring(1, segment.length - 1);
        params[paramName] = pathSegments[index];
        return true;
      }
      return segment === pathSegments[index];
    });
  }) as string;

  if (!matchingPath) {
    throw new Error('Path not found');
  }

  const operation = mockedAPI[matchingPath as keyof typeof mockedAPI];

  if (!operation) {
    throw new Error('Path operation not found');
  }

  const fn = operation[method.toLowerCase() as keyof typeof operation];

  // @ts-expect-error - this is very hard to fix and not worth the effort
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return fn(queryParams, body, params);
}

export function constructMockMiddleware<T extends object>(mockedAPI: T, basePath: string) {
  return (next: FetchLike) => async (url: string, opts: WretchOptions) => {
    const urlWithoutBasePath = url.replace(basePath, '');
    const [finalUrl, search] = urlWithoutBasePath.split('?') as [string, string | undefined];

    const queryParams = defaultParseSearch(search || '');

    let requestBody: unknown;

    if (opts.headers && new Headers(opts.headers).get('Content-Type') === 'text/csv') {
      requestBody = opts.body;
    } else if (opts.headers && new Headers(opts.headers).get('Content-Type') === 'application/octet-stream') {
      requestBody = opts.body; // Do not attempt to parse binary data
    } else {
      requestBody = opts.body ? JSON.parse(opts.body as string) : undefined;
    }

    return new Promise<Response>(async (resolve) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const responseBody = await executeMockedAPI(
          mockedAPI,
          finalUrl,
          opts.method as string,
          queryParams,
          requestBody,
        );

        const minTimeout = opts.method === 'GET' ? 250 : 500;

        await new Promise((r) => setTimeout(r, Math.floor(Math.random() * (1000 + minTimeout)) + minTimeout));

        if (responseBody instanceof Response) {
          resolve(responseBody);
        } else if (responseBody === undefined || responseBody === null) {
          resolve(new Response(null, { status: 200 }));
        } else {
          const response = new Response(JSON.stringify(responseBody), {
            headers: { 'Content-Type': 'application/json' },
          });
          resolve(response);
        }
      } catch {
        resolve(next(url, opts));
      }
    });
  };
}
