import type { components } from 'types/schemas/api-schema';

export const LOADING_THREATS: components['schemas']['threat'][] = [...new Array<number>(10)].map((_, i) => ({
  id: 'loading' + i,
  name: 'Loading threat',
  riskScore: {
    score: 8,
  },
  workflow: {
    status: 'open',
    assignee: null,
  },
  workflowAudit: {
    assignedToUpdatedBy: {
      type: 'loading',
      id: 'loading',
      name: 'loading',
    },
    messageUpdatedBy: {
      type: 'loading',
      id: 'loading',
      name: 'loading',
    },
    statusUpdatedBy: {
      type: 'loading',
      id: 'loading',
      name: 'loading',
    },
  },
  status: 'open',
  pipelineIds: ['loading'],
  tenantId: 'loading',
  evidence: [
    {
      id: 'loading',
      name: 'loading',
      createdAt: new Date().toISOString(),
      description: 'loading',
      externalRef: {
        type: 'loading',
        value: 'loading',
      },
      firstDetection: {
        end: new Date().toISOString(),
        start: new Date().toISOString(),
      },
      lastDetection: {
        end: new Date().toISOString(),
        start: new Date().toISOString(),
      },
      updatedAt: new Date().toISOString(),
      observations: [
        {
          createdAt: new Date().toISOString(),
          description: 'loading',
          id: 'loading',
          name: 'loading',
          updatedAt: new Date().toISOString(),
          perceptions: [
            {
              id: 'loading',
              confidenceScore: 8,
              createdAt: new Date().toISOString(),
              dimensions: [],
              facts: [],
              observationWindow: {
                start: new Date().toISOString(),
                end: new Date().toISOString(),
              },
              pipelineId: 'loading',
              tenantId: 'loading',
              updatedAt: new Date().toISOString(),
              entities: [
                {
                  type: 'ip',
                  value: 'loading',
                },
                {
                  type: 'ip',
                  value: 'loading 2',
                },
                {
                  type: 'ip',
                  value: 'loading 3',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'loading2',
      name: 'loading2',
      createdAt: new Date().toISOString(),
      description: 'loading2',
      externalRef: {
        type: 'loading',
        value: 'loading',
      },
      firstDetection: {
        end: new Date().toISOString(),
        start: new Date().toISOString(),
      },
      lastDetection: {
        end: new Date().toISOString(),
        start: new Date().toISOString(),
      },
      updatedAt: new Date().toISOString(),
      observations: [
        {
          createdAt: new Date().toISOString(),
          description: 'loading2-desc',
          id: 'loading2-id',
          name: 'loading2-name',
          updatedAt: new Date().toISOString(),
          perceptions: [
            {
              id: 'loading-perc',
              confidenceScore: 8,
              createdAt: new Date().toISOString(),
              dimensions: [],
              facts: [],
              observationWindow: {
                start: new Date().toISOString(),
                end: new Date().toISOString(),
              },
              pipelineId: 'loading',
              tenantId: 'loading',
              updatedAt: new Date().toISOString(),
              entities: [
                {
                  type: 'ip',
                  value: 'loading',
                },
                {
                  type: 'ip',
                  value: 'loading 22',
                },
                {
                  type: 'ip',
                  value: 'loading 3',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  evidenceWindow: {
    start: new Date().toISOString(),
    end: new Date().toISOString(),
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  assignee: 'loading',
}));

export const LOADING_THREATS_TIMELINE: components['schemas']['threatTimelineEvent'][] = [...new Array<number>(4)].map(
  (_, i) => ({
    user: {
      id: 'loading' + i,
      type: 'loading',
    },
    event: 'loading',
    message: 'loading',
    time: new Date().toISOString(),
  }),
);
