import type { ComponentType } from 'react';

import { GearIcon, Crosshair2Icon, HomeIcon, MixIcon, BarChartIcon } from 'components/common/Icons';

import { type MenuIconName } from './config';

export const menuIcons: Record<MenuIconName, ComponentType<any>> = {
  HomeIcon,
  Crosshair2Icon,
  MixIcon,
  BarChartIcon,
  GearIcon,
};
