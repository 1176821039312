import { getRouteApi } from '@tanstack/react-router';

import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useAnomalies, useAnomalyParams } from 'services/api/anomalies';
import { useTranslate } from 'services/i18n/useTranslate';
import { cn } from 'utils/styles';
import { HIGH_CONFIDENCE_SCORES, LOW_CONFIDENCE_SCORES, MEDIUM_CONFIDENCE_SCORES } from 'consts/anomalies';
import type { components } from 'types/schemas/api-schema';

import { Skeleton } from 'components/common/Skeleton';

import {
  ConfidenceScoreFilterBadge,
  PipelineFilterBadge,
  TenantFilterBadge,
  EntitiesFilterBadge,
  DimensionsFilterBadge,
} from '../common/AnomaliesFilterBadges';
import { useAnomaliesContext, type AnomaliesFilterProperty } from '../AnomaliesContext';

const Route = getRouteApi('/_app/anomalies');

const DEFAULT_TENANTS: components['schemas']['tenants'] = [];
const DEFAULT_PIPELINES: components['schemas']['fullPipeline'][] = [];

export const FilterBar = () => {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const anomalyParams = useAnomalyParams();
  const tenantsQuery = useTenants();
  const pipelinesQuery = usePipelines();
  const { pagination } = useAnomaliesContext();

  const anomaliesQuery = useAnomalies({
    ...anomalyParams,
    start: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
  });

  const hasData = !!anomaliesQuery.data?.data.length;

  const tenants = tenantsQuery.data?.data ?? DEFAULT_TENANTS;
  const pipelines = pipelinesQuery.data?.data ?? DEFAULT_PIPELINES;

  const isLoading = tenantsQuery.isLoading || pipelinesQuery.isLoading;

  const getDisplayValue = (paramKey: AnomaliesFilterProperty, value: string): string => {
    switch (paramKey) {
      case 'pipelineId':
        const pipeline = pipelines.find((p) => p.id === value);
        return pipeline ? pipeline.name! : isLoading ? '_'.repeat(5) : value;
      case 'tenantId':
        const tenant = tenants.find((t) => t.id === value);
        return tenant ? tenant.name : isLoading ? '_'.repeat(5) : value;
      case 'confidenceScore':
        switch (value) {
          case 'high':
            return t('common.score.high_range', {
              low: HIGH_CONFIDENCE_SCORES[0],
              high: HIGH_CONFIDENCE_SCORES[HIGH_CONFIDENCE_SCORES.length - 1]!,
            });
          case 'medium':
            return t('common.score.medium_range', {
              low: MEDIUM_CONFIDENCE_SCORES[0],
              high: MEDIUM_CONFIDENCE_SCORES[MEDIUM_CONFIDENCE_SCORES.length - 1]!,
            });
          case 'low':
            return t('common.score.low_range', {
              low: LOW_CONFIDENCE_SCORES[0],
              high: LOW_CONFIDENCE_SCORES[LOW_CONFIDENCE_SCORES.length - 1]!,
            });
          default:
            return value;
        }
      default:
        return value;
    }
  };

  const combinedParams = [
    ...(anomalyParams.confidenceScore || []),
    ...(anomalyParams.dimension || []),
    ...(anomalyParams.pipelineId || []),
    ...(anomalyParams.entity || []),
    ...(anomalyParams.tenantId || []),
  ];

  const handleRemoveParam = (paramKey: AnomaliesFilterProperty, value: string) => {
    void navigate({
      search: (prev) => {
        const prevPropFilter = prev[paramKey] || [];

        const newVal = prevPropFilter.filter((val) => val !== value);

        return {
          ...(prev || {}),
          [paramKey]: newVal.length ? newVal : undefined,
        };
      },
    });
  };

  const hasActiveFilters = !!combinedParams.length;

  return (
    <div
      className={cn(
        'my-2 mr-2 flex min-h-8 flex-wrap gap-2 rounded-1 px-2 py-1',
        !hasData || hasActiveFilters ? 'border border-neutral-a7 bg-accent-a1' : 'border-none bg-transparent',
      )}
      data-test-id="anomalies-filter-bar"
    >
      {searchParams.confidenceScore?.map((value) => (
        <ConfidenceScoreFilterBadge key={value} isActive onClick={() => handleRemoveParam('confidenceScore', value)}>
          {getDisplayValue('confidenceScore', value)}
        </ConfidenceScoreFilterBadge>
      ))}

      {anomalyParams.dimension?.map((value) => (
        <DimensionsFilterBadge key={value} isActive isInFilterBar onClick={() => handleRemoveParam('dimension', value)}>
          {getDisplayValue('dimension', value)}
        </DimensionsFilterBadge>
      ))}

      {anomalyParams.pipelineId?.map((value) => (
        <Skeleton key={value} isLoading={isLoading}>
          <PipelineFilterBadge isActive onClick={() => handleRemoveParam('pipelineId', value)}>
            {getDisplayValue('pipelineId', value)}
          </PipelineFilterBadge>
        </Skeleton>
      ))}

      {anomalyParams.entity?.map((value) => (
        <EntitiesFilterBadge key={value} isActive onClick={() => handleRemoveParam('entity', value)}>
          {getDisplayValue('entity', value)}
        </EntitiesFilterBadge>
      ))}

      {anomalyParams.tenantId?.map((value) => (
        <Skeleton key={value} isLoading={isLoading}>
          <TenantFilterBadge isActive onClick={() => handleRemoveParam('tenantId', value)}>
            {getDisplayValue('tenantId', value)}
          </TenantFilterBadge>
        </Skeleton>
      ))}
    </div>
  );
};
